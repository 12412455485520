 $(document).ready(function() {
 	var clipboard;
  	$('[data-toggle="tooltip"]').tooltip();
  	$('.datepicker').datepicker({
  		format: "mm/dd/yyyy"
  	});
  	var myCustomTemplates = {
  		custom1: function(context) {
  		return '<li class="dropdown">' +
			   '<a class="btn btn-default dropdown-toggle {{#if options.size}}btn-{{options.size}}{{/if}}" data-toggle="dropdown">' +
			   '<span class="glyphicon glyphicon-tags" aria-hidden="true"></span>' +
			   '<span class="caret"></span>' +
			   '</a>' +
			   '<ul class="dropdown-menu">' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{USER_LOGIN}}" tabindex="-1">User Login</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{USER_PASSWORD}}" tabindex="-1">User Password</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{TXN_ID}}" tabindex="-1">Transaction ID</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{LOGIN_URL}}"" tabindex="-1">Login URL</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{USER_FIRST_NAME}}" tabindex="-1">User First Name</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{USER_LAST_NAME}}" tabindex="-1">User Last Name</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{TXN_DATE}}" tabindex="-1">Transaction Date</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{TXN_AMT}}" tabindex="-1">Transaction Amount</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{USER_PACKAGE}}" tabindex="-1">User Package</a></li>' +
			   '<li><a data-wysihtml5-command="insertHTML" data-wysihtml5-command-value="{{USER_DELETE_DATE}}" tabindex="-1">User Delete Date</a></li>' +
			   '</ul>' +
			   '</li>';
  		}
	};


	var editor = $('textarea.editor').wysihtml5({
		custom1: true,
  		customTemplates: myCustomTemplates
	});

	$('#item-table').DataTable({
		"autoWidth": false,
		"paging":   false,
        "ordering": false,
        "info":     false,
        "filter": 	false,
        "columnDefs": [
		    { "width": "75%", "targets": 0 }
	  	]

	});
	$('#url-table').DataTable({
		"autoWidth": false,
		"paging":   false,
        "ordering": false,
        "info":     false,
        "filter": 	false,
        "columnDefs": [
		    { "width": "75%", "targets": 0 }
	  	]
	});
	$('#payment-history tfoot th').each( function () {
        var title = $('#payment-history thead th').eq( $(this).index() ).text();
        $(this).html( '<input type="text" placeholder="Search" />' );
    } );

    var table = $('#payment-history').DataTable({
        "order": [[ 6, "desc" ]],
        "deferRender": true,
		"data": $('#service-info').data('transactions'),
		"stateSave": true
    });

    // Apply the search
    table.columns().every( function () {
        var that = this;

        $( 'input', this.footer() ).on( 'keyup change', function () {
            if ( that.search() !== this.value ) {
                that
                    .search( this.value )
                    .draw();
            }
        } );
    } );

    var fd = $('#feeds-datatable');
    var data1 = $('#service-info');
    var data2 = $('#service-info').data('feeds');
    $('#feeds-datatable').DataTable({
        "deferRender": true,
        "data": $('#service-info').data('feeds'),
        "stateSave": true
    });
    var fd2 = $('#feeds-datatable');

    // Others are just a regular datatables.
	// If they have a prepared data, these data are stored in the 'items' parameter.
	var dt_init = {
        "deferRender": true,
        "stateSave": true
    };
    if ($('#service-info')) {
        if ($('#service-info').data('items')) {
            dt_init.data = $('#service-info').data('items');
        }
    }
    $('.datatables').DataTable(dt_init);

	$('.tags').select2({
	  tags:true
	});

	$(document).on('click', '.url-add', function(e) {
		$('#url-add').modal('show');
	});

	$('#url-add').on('show.bs.modal', function(e) {
		$("#url-add textarea[name='_link']").val('');
		$("#url-add textarea[name='_link']").focus();
		var error_div = $('#url-add div.alert');
		if (error_div.length)
		{
			error_div.remove();
		}
	});

	$(document).on('click', '.item-add', function(e) {
		$('#item-add').modal('show');
	});

	$('#item-add').on('show.bs.modal', function(e) {
		$("#item-add textarea[name='_link']").val('');
		$("#item-add textarea[name='_link']").focus();
		var error_div = $('#item-add div.alert');
		if (error_div.length)
		{
			error_div.remove();
		}
	})

	$(document).on('click', '#url-save', function(e) {
		var $btn = $(this).button('loading')
		$.ajax({
			'data': {
				'_link': $("#url-add textarea[name='_link']").val(),
				'_token': $("input[name='_token']").val(),
				'feed_id': $("input[name='id']").val(),
			},
			'url': '/url/create',
			'method': 'POST'
		}).fail(function(data, status, error) {
			var error = '<div class="alert alert-danger" role="alert">'+ JSON.parse(data.responseText)._link[0] +'</div>';
			var error_div = $('#url-add div.alert');
			if (error_div.length)
			{
				error_div.remove();
			}
			$btn.button('reset');
			$('#url-add .modal-body').prepend(error);
		}).done(function(data, status, error) {
			if (data.errors)
			{
				if (data.errors['9999'])
				{
					var error = '<div class="alert alert-danger" role="alert">'+ data.errors['9999'] +'</div>';
				} else {
					var error = '<div class="alert alert-danger" role="alert">'+ data.errors +'</div>';
				}
				var error_div = $('#url-add div.alert');
				if (error_div.length)
				{
					error_div.remove();
				}
				$('#url-add .modal-body').prepend(error);
			} else {
				var error_div = $('#url-add div.alert');
				if (error_div.length)
				{
					error_div.remove();
				}
			}
			$btn.button('reset');
			if (data.success)
			{
				fetchUrlRow();
				$('#url-add').modal('hide');
			}
		});
	});

	$(document).on('click', '#item-save', function(e) {
		var $btn = $(this).button('loading')
		$.ajax({
			'data': {
				'_link': $("#item-add textarea[name='_link']").val(),
				'_token': $("input[name='_token']").val(),
				'feed_id': $("input[name='id']").val(),
			},
			'url': '/item/create',
			'method': 'POST'
		}).fail(function(data, status, error) {
			var error = '<div class="alert alert-danger" role="alert">'+ JSON.parse(data.responseText)._link[0] +'</div>';
			var error_div = $('#item-add div.alert');
			if (error_div.length)
			{
				error_div.remove();
			}
			$btn.button('reset');
			$('#item-add .modal-body').prepend(error);
		}).done(function(data, status, error) {
			if (data.errors)
			{
				var error = '<div class="alert alert-danger" role="alert">'+ data.errors['9999'] +'</div>';
				var error_div = $('#item-add div.alert');
				if (error_div.length)
				{
					error_div.remove();
				}
				$('#item-add .modal-body').prepend(error);
			} else {
				var error_div = $('#item-add div.alert');
				if (error_div.length)
				{
					error_div.remove();
				}
			}
			$btn.button('reset');
			if (data.success)
			{
				fetchItemRow();
				$('#item-add').modal('hide');
			}
		});
	});

	function fetchUrlRow() {
		var urlTableBody = $('#url-table-rows');
		if (urlTableBody.length)
		{
			$.ajax({
				'method': 'GET',
				'url': '/url/get/table/row?' + $.param({
					'feed_id' : $("input[name='id']").val(),
					'_token': $("input[name='_token']").val(),
				})
			}).done(function(data) {
				urlTableBody.html("");
				urlTableBody.html(data);
				$('input[type="number"].max').on('blur', function() {
				    var url_id = $(this).data('id');
				    var value = $(this).val();
				    $.ajax({
				        'data': {
				            '_token': $("input[name='_token']").val(),
				            'url_id': url_id,
				            'value': value,
				        },
				        'method': 'POST',
				        'url': '/url/max-item/update'
				    }).done(function (data) {
				       console.log(data);
				    });
				});
			});
		}
	}
	fetchUrlRow();

	function fetchItemRow() {
		var itemTableBody = $('#item-table-rows');
		if (itemTableBody.length)
		{
			$.ajax({
				'method': 'GET',
				'url': '/item/get/table/row?' + $.param({
					'feed_id' : $("input[name='id']").val(),
					'_token': $("input[name='_token']").val(),
				})
			}).done(function(data) {
				itemTableBody.html("");
				itemTableBody.html(data);

			});
		}
	}
	fetchItemRow();

	$('#item-remove-confirmation').on('show.bs.modal', function(e) {
		var button = $(e.relatedTarget);
		var item_id = button.data('id');

		var modal = $(this);
		modal.find('#item-delete').data('id', item_id);
	});

	$('#url-remove-confirmation').on('show.bs.modal', function(e) {
		var button = $(e.relatedTarget);
		var url_id = button.data('id');

		var modal = $(this);
		modal.find('#url-delete').data('id', url_id);
	});

	$('#modal-payment-system').on('show.bs.modal', function(e) {
		clipboard = new Clipboard('.btn');
		var button = $(e.relatedTarget);
		var api = button.data('api');
		var base = button.data('base');
		var modal = $(this);
		var payment = button.html();
		var url = base + '/' + payment.trim() + '/' + api;
		modal.find('#ipn').val(url);
	});

	$('#transaction-modal').on('show.bs.modal', function(e) {
		var button = $(e.relatedTarget);
		var id = button.data('id');

		var modal = $(this);
		$.getJSON('subscription/transaction/' + id, function(data) {
			$('#product_number').val(data.product_number);
			$('#product_name').val(data.product_name);
			$('#product_type').val(data.product_type);
			$('#trans_amount').val(data.trans_amount);
			$('#trans_receipt').val(data.trans_receipt);
			$('#payment_system').val(data.user_payment_system);
			$('#trans_time').val(data.trans_time);
		});
	});

	$('#modal-payment-system').on('hide.bs.modal', function(e) {
		clipboard.destroy();
	});

	$('#modal-multiple-url').on('show.bs.modal', function(e) {
		clipboard = new Clipboard('.btn');
		var button = $(e.relatedTarget);
		var id = button.data('id');
		var data = $.getJSON ('/feed/urls/' + id, function(data) {
			var base = "http://news-round.com/feeds";
			var html = "http://news-round.com/news/feed";
			$('#url-1').val(base +'/'+ data['key'] + '-' + data['slug']);
			$('#url-2').val(base +'/'+ data['key'] + '-' + data['slug'] + '.rss');
			$('#url-3').val(base +'/'+ data['key'] + '-' + data['slug'] + '.xml');
			$('#url-4').val(base + '/view/' + data['key'] + '-' + data['slug']);
			$('#url-5').val(base +'/'+data['category']+'/'+data['key'] + '-' + data['slug']);
			$('#url-6').val(base +'/'+data['category']+'/'+data['key'] + '-' + data['slug'] + '.rss');
			$('#url-7').val(base +'/'+data['category']+'/'+data['key'] + '-' + data['slug'] + '.xml');
			var all = $('#url-1').val() + '\n' + $('#url-2').val() + '\n' + $('#url-3').val() + '\n' + $('#url-4').val() + '\n' + $('#url-5').val() + '\n' + $('#url-6').val() + '\n' + $('#url-7').val();
			$('#url-8').val(all);
		});
	});

	$('#modal-multiple-url').on('hide.bs.modal', function(e) {
		clipboard.destroy();
	});

	$('#modal-single-url').on('show.bs.modal', function(e) {
		clipboard = new Clipboard('.btn');
		var button = $(e.relatedTarget);
		var id = button.data('id');
		var data = $.getJSON ('/feed/urls/' + id, function(data) {
			var base = "http://news-round.com/feeds";
			var html = "http://news-round.com/news/feed";
			$('#single-url-1').val(base +'/'+data['key'] + '-' + data['slug']);
			if ($( "#single-url-2" ).length) {
				$('#single-url-2').val(html +'/'+data['key'] + '-' + data['slug'] + '.html');
			}
		});
	});

	$('#modal-single-url').on('hide.bs.modal', function(e) {
		clipboard.destroy();
	});



	$(document).on('click', '#item-delete', function(e) {
		var item_id = $(this).data('id');
		$.ajax({
			'data': {
				'item_id': item_id,
				'_token': $("input[name='_token']").val(),
			},
			'method': 'POST',
			'url': '/item/destroy'
		}).done(function(data) {
			fetchItemRow();
			$('#item-remove-confirmation').modal('hide');
		});
	});

	$('#user-remove-confirmation').on('show.bs.modal', function(e) {

		var button = $(e.relatedTarget);
		var url = button.attr('href');
		var role = button.data('role');
		var name = button.data('name');
		var email = button.data('email');
		var id = button.data('id');
		var modal = $(this);
		var body = "You're about to delete "+ role +" "+ name +" with email "+ email +". This is cannot be undone. Do you want to delete this user?";
		var text = modal.find('.modal-body').text(body);
		$('#user-delete').data('id', id);
	});

	$(document).on('click', '#user-delete', function(e) {
		var id = $(this).data('id');
		$.ajax({
			'data': { 'id': id, '_token': $("input[name='_token']").val() },
			'method': 'POST',
			'url': '/users/destroy'
		}).done(function(data) {
			window.location.reload();
		});
	});

	$(document).on('click', '#url-delete', function(e) {
		var url_id = $(this).data('id');
		$.ajax({
			'data': {
				'url_id': url_id,
				'_token': $("input[name='_token']").val(),
			},
			'method': 'POST',
			'url': '/url/destroy'
		}).done(function(data) {
			fetchUrlRow();
			$('#url-remove-confirmation').modal('hide');
		});
	});

	$('table.datatables .payment-enabled').change(function() {
		if($(this).is(":checked")) {
	      	enabledPayment($(this).data('id'), 1);
	    } else {
	       	enabledPayment($(this).data('id'), 0);
	    }
	  });

	function enabledPayment(id,value)
	{
	    $.post('/payment-system/enabled',
			{'id':id, 'value':value},
			function(data) {
		});
	}

	determineTrialDaysInput();
	function determineTrialDaysInput()
	{
		if ($('select[name="type"]').val() != 'trial') {
			$('input[name="days"]').parent().parent().hide();
			$('input[name="days"]').val('');
		} else {
			$('input[name="days"]').parent().parent().show();
		}
	}

	function saveProject()
	{
		$.ajax({
			url: '/project/store',
			data: {
				'name': $('#project-add-modal input[name="name"]').val(),
				'_token': $('input[name="_token"]').val()
			},
			method: 'POST'
		}).done(function(data) {
			if (data.success) {
				$.ajax({
					url: '/project/all',
					method: 'GET'
				}).done(function(projects)
				{
					var option = '<option value="">Select A Project...</option>';
					$.each(projects, function(key, value) {
						if (data.id == key)
						{
							option += '<option value="' + key + '" selected="selected">' + value + '</option>';
						} else {
							option += '<option value="' + key + '">' + value + '</option>';
						}
					});
					$('#project_id').html(option);
					$('#project-add-modal').modal('hide');
				})
			}
		});
	}

	function deleteTableLine(element)
	{
        var href = element.data('path');
        var feed_id = element.data('value');
        var table = $('#feeds-datatable').DataTable();
        var table_entry = $('#feed-item-' + feed_id);
        var trash_icon = table_entry.find('.glyphicon-trash');
        trash_icon.addClass('glyphicon-spin');

        $.ajax({
            url: href,
            type: 'get',
            success: function(result) {
                table.row(table_entry).remove().draw('page');
            },
            error: function(xhr, status, error) {
                console.log('Error communicating with server: ' + error.message);
                trash_icon.removeClass('glyphicon-spin');
            }
        });
    }

	$(document).on('change', 'select[name="type"]', function (e) {
		determineTrialDaysInput();
	});

	$(document).on('click', '#project-add-button', function (event) {
		event.preventDefault();
		$('#project-add-modal').modal('show');
	});
	$(document).on('click', '#project-add', function (e) {
		e.preventDefault();
		saveProject();
	});

	$(document).on('click', '#feed-project-add', function (e) {
		e.preventDefault();
		$('#project-add-modal').modal('show');
	});
	// $('#project-add-modal').on('hide.bs.modal', function(e) {
	// 	location.reload();
	// });

    $(document).on("click", ".feed-delete", function(e){
        deleteTableLine($(this));
    });
 });
